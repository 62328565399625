import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a detail-oriented web developer and motion designer, based out of Northeast Iowa.`}</p>
    </blockquote>
    <p>{`I graduated from the University of Northern Iowa in 2017, earning my Bachelor's with a double major in `}<strong parentName="p">{`Digital Media Production`}</strong>{` and `}<strong parentName="p">{`Interactive Digital Studies`}</strong>{`. After working for Coloff Digital for 2½ years, I left to start my own business, Red Tadpole.`}</p>
    <p>{`While my focus tends to lie in web development and motion design, I'm also experienced and passionate about photo manipulation and editing. I hold high value in photography's ability to capture a moment that may otherwise become foggy or forgotten.`}</p>
    <p>{`What's that? You want to know about who I am outside of work? How thoughtful. 😊
When I'm not at my computer, I love spending time outdoors; rock climbing, running, and getting in the water are among my favorite outdoor activities, but the list goes on. I also enjoy building things with my hands. To relax, I like to catch up on my favorite shows or game.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      